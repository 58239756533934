import React, {useState, useRef, useEffect, useContext} from 'react'
import '../assets/css/MainPage.css'
import CalculatorForm from './CalculatorForm'
import FeedBackForm from './FeedBackForm';
import CustomPopup from '../UI/popup/CustomPopup';
import MyButton from '../UI/button/MyButton';
import PayLinks from './PayLinks';
import SelectWrapper from './SelectWrapper';
import { beneficiari, beneficiariMod } from '../assets/data/option';
import { FormData } from '../context/FormContextData';


export default function MainPage() {
  const [calculationResult, setCalculationResult] = useState(null);
  const [visibility, setVisibility] = useState(false);
  const [secondPopup, setSecondPopup] = useState(false);
  const [beneficiar, setBeneficiar] = useState(null);
  const {instanceType} = useContext(FormData);
  const tableRef = useRef(null);
  useEffect(() => {
    // Focus on the table when it is rendered
    if (tableRef.current) {
      tableRef.current.focus();
      tableRef.current.classList.add('focused-table');
    }
  }, [calculationResult]);
  const popupCloseHandler = (e) => {
    setVisibility(!visibility);
  };
  // Callback function to handle the result
  const handleResultReceived = (result) => {
    setCalculationResult(result);
  };
  const secondPopupCloseHandler = (e) => {
    setSecondPopup(!secondPopup);
  }
  const print = (e) => {
    window.print();
  }
  return (
    <div className='main-page'>
      <div className='feedback-button'>
        <MyButton
          body="Lasa un Feedback"
          onClick={popupCloseHandler}
        />
      </div>
      <CalculatorForm onResultReceived={handleResultReceived} />
      <div className='right-container' tabIndex={0} ref={tableRef}>
        {calculationResult && (
          <div className='results-container'>
            <table className='result-table'>
              <thead>
                <tr>
                  <th>Taxa</th>
                  <th>Suma</th>
                </tr>
              </thead>
              <tbody>
                {calculationResult.map((value, index) => (
                  <tr key={index}>
                    <td>Taxa de stat {index + 1}</td>
                    <td>{value.toFixed(2)} lei</td>
                  </tr>
                ))}
                {calculationResult.length > 1 && 
                <tr>
                <td>Total</td>
                <td>
                  {(calculationResult.reduce((sum, value) => sum + value, 0)).toFixed(2)} lei
                </td>
              </tr>}
              </tbody>
            </table>
            <div>
              <div className='rechizite-container'>
              <span><b>Rechizite bancare pentru achitarea taxei de stat</b></span>

              <span> <b>Beneficiar:</b> Ministerul Finanțelor</span>
              <span> <b>Codul IBAN:</b> MD94TRGAAA14222001000000</span>
              <span> <b>Codul fiscal:</b> 1006601000037</span>
              <span> <b>Prestatorul beneficiar:</b> Ministerul Finanțelor- Trezoreria de Stat</span>
</div>
            </div>
            <table className='result-table'>
            <thead>
                <tr>
                  <th>Taxa</th>
                  <th>Suma</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                  <td>Taxa de timbru</td>
                  <td>{(200).toFixed(2)} lei</td>
                </tr>
              </tbody>
            </table>
            <div>
            <SelectWrapper
                options={instanceType && (instanceType === "Apel" || instanceType === "Recurs") ? beneficiariMod : beneficiari }
                onChange={setBeneficiar}
                name="beneficiari"
                value={beneficiar}
                label="Beneficiar"
              />
            </div>
           {beneficiar &&  
           <div className='rechizite-container'>
              
                <span><b>Rechizite bancare pentru achitarea taxei de timbru</b></span>

               <span> <b>Beneficiar:</b>  {beneficiar.label} </span>

               <span> <b>Codul IBAN:</b> {beneficiar.iban} </span>

               <span> <b>Codul fiscal:</b> {beneficiar.cod_fiscal} </span>

               <span> <b>Prestatorul beneficiar:</b> Ministerul Finanțelor- Trezoreria de Stat</span>
           </div>}
            <div className='buttons-wrapper'>
            <MyButton
                body="Imprima"
                onClick={print}
                type="button"
                className="print-button"
              />
            <MyButton
                body="Achită Taxa"
                onClick={secondPopupCloseHandler}
                type="button"
                className="pay-button"
              />
            </div>
          </div>
        )}
      </div>
      <CustomPopup
        onClose={popupCloseHandler}
        show={visibility}
        title="Feedback"
      >
        <FeedBackForm setVisibility={setVisibility} />
      </CustomPopup>
      <CustomPopup
        onClose={secondPopupCloseHandler}
        show={secondPopup}
        title="Achitare"
      >
        <PayLinks />
      </CustomPopup>
      
    </div>
  );
}

import React from 'react'
import '../assets/css/Navbar.css'
import Logo from './Logo'

export default function Navbar() {
  const handleClick = () => {
    window.location.href = '/';
  }
  return (
    <nav>
      <div className='nav-title'>
        <Logo 
        onClick={handleClick}/>
      </div>
    </nav>
  )
}

import React, { useContext, useState } from 'react'
import { FormData } from '../context/FormContextData';
import calcService from '../service/CalcService';
import MyButton from '../UI/button/MyButton'

export default function FeedBackForm({setVisibility}) {
    const [issueDescription, setIssueDescription] = useState("");
    const {formDataList} = useContext(FormData);
    let lastCalcDetails
   if(formDataList[0]){
     lastCalcDetails = "Tip actiune:\n" + formDataList[0].actionType + "\nTip cerere:\n" + formDataList[0].requestType;
   }
    const [isDisabled, setIsDisabled] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsDisabled(true);
        const response = await calcService.sendFeedback(issueDescription,lastCalcDetails);
        console.log(response);
        setVisibility(false);
        setTimeout(() => {
            setIsDisabled(false);
          }, 5000);
        };
    
  return (
    <form className='feedback-form' onSubmit={handleSubmit}>
        <div className='feedback-container'>
            <div className='input-wrapper'>
                <label htmlFor="issue-description">Descrierea</label>
                <textarea 
                id="issue-description" cols="30" rows="10"
                value={issueDescription}
                onChange={(e) => setIssueDescription(e.target.value)}
                >
                </textarea>
            </div>
            <MyButton
            disabled={isDisabled}
            body="Trimite ->"
            />
        </div>
    </form>
  )
}
